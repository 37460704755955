import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { useIntl } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { appRedirect } from "../redux/app/actions";
import {
	PageContentWrapper,
	PageContent,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import { colors } from "../assets/styles/vars";
import PageHeader from "../components/Common/PageHeader";
import { Breadcrumb, CustomLink } from "../assets/styles/globalCommon";
import { Link } from "gatsby";
import ModulesList from "../components/Common/ModulesList";
import { ReactSVG } from "react-svg";
import BannerTwoCol from "../components/Common/BannerTwoCol";
import { protegemosDatosModule } from "../data/global-data";

const SuscripcionSuccessPage = ({ authResolved, loggedIn, appRedirect }) => {
	const intl = useIntl();

	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
	}, [authResolved, loggedIn]);

	return (
		<Layout>
			<Seo title="Suscripción Exitosa" />
			<section>
				<PageHeader title="Suscripción Exitosa" />

				<PageContentWrapper bgColor={colors.grayF2}>
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<Breadcrumb>
									<Link to="/account">Mi cuenta</Link>
									<a>></a>
									<a>Suscripción exitosa</a>
								</Breadcrumb>
								<h3>Tu suscripción fue realizada con éxito</h3>
								<p>
									Estamos encantados de tenerte con nosotros 😃, ahora
									a disfrutar de una experiencia digital en la nieve.
									Recuerda que puedes hablar con nosotros cuando
									quieras, nuestro objetivo es mejorar con el feedback
									vuestro, así que te invitamos a escribirnos las veces
									que tu quieras.
								</p>
								<br />
								<br />

								<CustomLink to="/" className="withIcon">
									<p>Ir al inicio</p>
									<ReactSVG
										src="/img/icons/icon-arrow-right.svg"
										wrapper="span"
										className="icon"
									/>
								</CustomLink>
							</div>

							<div className="pageCol rightCol">
								<ModulesList items={protegemosDatosModule} />
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>

				<BannerTwoCol
					direction="right"
					img="/img/discord.png"
					title="¿Tienes alguna duda?"
					text="Te invitamos a particiar de ¡nuestro Discord! Si leiste bien, Fresh Snow tiene un canal de Discord para la comunidad de esquiadores.<br/><br/>Allí podrás resolver dudas, organizar viajes, hablar con expertos o aconsejar a principiantes, en definitiva, hacer amigos y formar parte del crecimiento de una comunidad de esquiadores en constante evolución."
					buttonUrl="https://discord.gg/sf8yKSbN"
					buttonText="Únete a discord"
					buttonIcon="/img/icons/icon-discord.svg"
				/>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		// usuario: state.auth.usuario,
	};
};

const mapDispatchToProps = {
	appRedirect,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SuscripcionSuccessPage);
